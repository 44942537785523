import React, {Component} from 'react';
import mixitup from 'mixitup';
import deeds from "../../images/deeds.png";
import lots from "../../images/lots.png";
import water from "../../images/water.png";
import calculator from "../../images/calculator.png";
import game from "../../images/game.png";
import giphy from "../../images/giphy.png";
import notes from "../../images/notes.png";
import gm from "../../images/gm.png";
import nimes from "../../images/nimes.png";
import mogo from "../../images/mogo.png";
import desire from "../../images/desire.png";
import gpt from "../../images/gpt.png";
import onpoint from "../../images/onpoint.png";
import "./works.css";

class Works extends Component {

    componentDidMount(){
        let mixer = mixitup('.works__content', {
            load: {
                filter: '.js'
            },
            "animation": {
                "duration": 511,
                "nudge": false,
                "reverseOut": false,
                "effects": "fade scale(0.64) stagger(47ms)"
            }
        });
    }

    render(){

        return (
            <div className="works">

                <div className="works__wrapper flex jc-sb ai-c">
                    <div className="works__buttons flex">
                   
                        <button class="gallery__btn" data-filter=".js">HTML  CSS  JS</button>
                        <button class="gallery__btn" data-filter=".react">React</button>

                
                    </div>
                    <div className="works__content flex">
                        <a href="https://notforgetlist.netlify.app/" className="works__item mix js" target="_blank" rel="noopener noreferrer">
                            <div className="works__item-img" style={{backgroundImage: `url(${deeds})`}}></div>
                            <div className="works__item-title flex ai-c jc-sb"> 
                                <p>To-do</p>
                                <a href="https://github.com/mariekruie/notforgetlist" target="_blank" rel="noopener noreferrer">{"<"} code  {">"}</a>
                            </div>
                        </a>
                        <a href="https://dice-and-lots.netlify.app" className="works__item mix js" target="_blank" rel="noopener noreferrer">
                            <div className="works__item-img" style={{backgroundImage: `url(${lots})`}}></div>
                            <div className="works__item-title flex ai-c jc-sb">
                                <p>dice & lots</p>
                                <a href="https://github.com/mariekruie/castinglots" target="_blank" rel="noopener noreferrer">{"<"} code  {">"}</a>
                            </div>
                        </a>
                        <a href="https://twolitersaday.netlify.app/" className="works__item mix js" target="_blank" rel="noopener noreferrer">
                            <div className="works__item-img" style={{backgroundImage: `url(${water})`}}></div>
                            <div className="works__item-title flex ai-c jc-sb">
                                <p>water app</p>
                                <a href="https://github.com/mariekruie/twolitersaday" target="_blank" rel="noopener noreferrer">{"<"} code  {">"}</a>
                            </div>
                        </a>
                        <a href="https://canyoucount.netlify.app/" className="works__item mix js" target="_blank" rel="noopener noreferrer">
                            <div className="works__item-img" style={{backgroundImage: `url(${calculator})`}}></div>
                            <div className="works__item-title flex ai-c jc-sb">
                                <p>can you count?</p>
                                <a href="https://github.com/mariekruie/calculator" target="_blank" rel="noopener noreferrer">{"<"} code  {">"}</a>
                            </div>
                        </a>
                        <a href="https://catchmegame.netlify.app/" className="works__item mix js" target="_blank" rel="noopener noreferrer">
                            <div className="works__item-img" style={{backgroundImage: `url(${game})`}}></div>
                            <div className="works__item-title flex ai-c jc-sb">
                                <p>catch-me game</p>
                                <a href="https://github.com/mariekruie/catchmegame" target="_blank" rel="noopener noreferrer">{"<"} code  {">"}</a>
                            </div>
                        </a>
                        <a href="https://test-onpoint.netlify.app/" className="works__item  mix react" target="_blank" rel="noopener noreferrer">
                            <div className="works__item-img" style={{backgroundImage: `url(${onpoint})`}}></div>
                            <div className="works__item-title flex ai-c jc-sb">
                                <p>pharma</p>
                                {/* <a href="https://github.com/mariekruie/onpoint" target="_blank" rel="noopener noreferrer">{"<"} code  {">"}</a> */}
                            </div>
                        </a>
                        <a href="https://notesandthoughts.netlify.app/" className="works__item  mix react" target="_blank" rel="noopener noreferrer">
                            <div className="works__item-img" style={{backgroundImage: `url(${notes})`}}></div>
                            <div className="works__item-title flex ai-c jc-sb">
                                <p>diary</p>
                                <a href="https://github.com/mariekruie/react-mythoughts" target="_blank" rel="noopener noreferrer">{"<"} code  {">"}</a>
                            </div>
                        </a>
                        <a href="https://react-mygiphy-api.netlify.app/" className="works__item mix react" target="_blank" rel="noopener noreferrer">
                            <div className="works__item-img" style={{backgroundImage: `url(${giphy})`}}></div>
                            <div className="works__item-title flex ai-c jc-sb">
                                <p>my giphy</p>
                                <a href="https://github.com/mariekruie/react-giphy-api" target="_blank" rel="noopener noreferrer">{"<"} code  {">"}</a>
                            </div>
                        </a>
                        <a href="https://avtoclub-gm.ru/" className="works__item mix js" target="_blank" rel="noopener noreferrer">
                            <div className="works__item-img" style={{ backgroundImage: `url(${gm})` }}></div>
                            <div className="works__item-title flex ai-c jc-sb">
                                <p>avtoclub gm</p>
                            </div>
                        </a>
                        <a href="https://nimes.netlify.app/" className="works__item mix js" target="_blank" rel="noopener noreferrer">
                            <div className="works__item-img" style={{ backgroundImage: `url(${nimes})` }}></div>
                            <div className="works__item-title flex ai-c jc-sb">
                                <p>nimes</p>
                                <a href="https://github.com/mariekruie/nimes" target="_blank" rel="noopener noreferrer">{"<"} code  {">"}</a>
                            </div>
                        </a>
                        <a href="https://layout-web-two.netlify.app/" className="works__item mix js" target="_blank" rel="noopener noreferrer">
                            <div className="works__item-img" style={{ backgroundImage: `url(${mogo})` }}></div>
                            <div className="works__item-title flex ai-c jc-sb">
                                <p>mogo</p>
                                <a href="https://github.com/mariekruie/layout-web-two" target="_blank" rel="noopener noreferrer">{"<"} code  {">"}</a>
                            </div>
                        </a>
                        <a href="https://loved-once.netlify.app/" className="works__item mix js" target="_blank" rel="noopener noreferrer">
                            <div className="works__item-img" style={{ backgroundImage: `url(${desire})` }}></div>
                            <div className="works__item-title flex ai-c jc-sb">
                                <p>loved</p>
                                <a href="https://github.com/mariekruie/layout-web-one" target="_blank" rel="noopener noreferrer">{"<"} code  {">"}</a>
                            </div>
                        </a>
                        <a href="https://funtik.netlify.app/" className="works__item mix react" target="_blank" rel="noopener noreferrer">
                            <div className="works__item-img" style={{ backgroundImage: `url(${gpt})` }}></div>
                            <div className="works__item-title flex ai-c jc-sb"> 
                                <p>gpt</p>
                                <a href="https://github.com/mariekruie/react-webpage" target="_blank" rel="noopener noreferrer">{"<"} code  {">"}</a>
                            </div>
                        </a>
                    </div>
                </div>
               
            </div>
        )
    }
}

export default Works;
