import React, {Component} from 'react';
import deeds from "../../images/deeds.png";
import lotsMobile from "../../images/lots-mobile.png";
import water from "../../images/water.png";
import game from "../../images/game.png";
import calculator from "../../images/calculator.png";
import giphy from "../../images/giphy.png";
import notes from "../../images/notes.png";
import gm from "../../images/gm.png";
import nimes from "../../images/nimes.png";
import mogo from "../../images/mogo.png";
import desire from "../../images/desire.png";
import gpt from "../../images/gpt.png";
import onpoint from "../../images/onpoint.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faAngleLeft, faAngleRight} from '@fortawesome/free-solid-svg-icons';
import './works-mobile.css';

class WorksMobile extends Component{

    state = { 
        works: [
            {
                title:'Dice & lots',
                image: lotsMobile,
                url: 'https://dice-and-lots.netlify.app',
                code: 'https://github.com/mariekruie/castinglots'
            },
            {
              title:'Pharma',
              image: onpoint,
              url: 'https://test-onpoint.netlify.app/'
            },
            {
              title:'To-Do',
              image: deeds,
              url: 'https://notforgetlist.netlify.app/',
              code: 'https://github.com/mariekruie/notforgetlist'
            },
            {
                title:'Water app',
                image: water,
                url: 'https://twolitersaday.netlify.app/',
                code: 'https://github.com/mariekruie/twolitersaday'
            },
            {
                title:'Can you count?',
                image: calculator,
                url: 'https://canyoucount.netlify.app/',
                code: 'https://github.com/mariekruie/calculator'
            },
            {
                title:'Catch me game',
                image: game,
                url: 'https://catchmegame.netlify.app/',
                code: 'https://github.com/mariekruie/catchmegame'
            },
            {
                title:'Diary',
                image: notes,
                url: 'https://notesandthoughts.netlify.app/',
                code: 'https://github.com/mariekruie/react-mythoughts'
            },
            {
                title:'My giphy',
                image: giphy,
                url: 'https://react-mygiphy-api.netlify.app/',
                code: 'https://github.com/mariekruie/react-giphy-api'
            },
            {
              title:'avtoclub gm',
              image: gm,
              url: 'https://avtoclub-gm.ru/'
            },
            {
                title:'nimes',
                image: nimes,
                url: 'https://nimes.netlify.app/',
                code: 'https://github.com/mariekruie/nimes'
            },
            {
                title:'web layout',
                image: mogo,
                url: 'https://layout-web-two.netlify.app/',
                code: 'https://github.com/mariekruie/layout-web-two'
            },
            {
                title:'web layout',
                image: desire,
                url: 'https://loved-once.netlify.app/',
                code: 'https://github.com/mariekruie/layout-web-one'
            },
            {
                title:'gpt',
                image: gpt,
                url: 'https://funtik.netlify.app/',
                code: 'https://github.com/mariekruie/react-webpage'
            }
        ],
        idx: 0
    }

    handlePrevClick = () => {
        if(this.state.idx >0){
          this.setState({
            idx: this.state.idx - 1
          })
        } else{
          this.setState({
            idx: this.state.works.length-1
          })
        }
      }
    
      handleNextClick = () => {
       if(this.state.idx < this.state.works.length-1){
          this.setState({
            idx: this.state.idx + 1
          })
        }else{
          this.setState({
            idx: 0
          })
        }
      }

    render(){

        const {works, idx} = this.state;

        const codeLink = works[idx].code ? <a href={works[idx].code} target="_blank" rel="noopener noreferrer">{"<"} code {">"}</a> : null;
        
        return (
            <div className="works-mobile">

                <div className="works-mobile__content flex jc-c">
                   
                    <a href={works[idx].url} className="works-mobile__img" target="_blank" rel="noopener noreferrer">
                        <div style={{backgroundImage: `url(${works[idx].image})`}}></div>
                    </a>

                    <div className="works-mobile__text flex jc-sb ai-c">
                        <div className="works-mobile__btn" onClick={this.handlePrevClick}>   
                          <FontAwesomeIcon icon={faAngleLeft} size="2x" className="works-mobile__btn-icon"/>
                        </div>
                        <p>{works[idx].title}</p>
                        {codeLink}
                        <div className="works-mobile__btn" onClick={this.handleNextClick}>   
                        <FontAwesomeIcon icon={faAngleRight} size="2x" className="works-mobile__btn-icon"/>
                        </div>
                    </div>

    
                </div>
               
            </div>
        )
    }

}

export default WorksMobile;

